/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { publicRoute } from "./constant/PublicRoute";

const RouteGuard = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const findPublicPath = publicRoute.some(
    (path) => path === window.location.pathname
  );
  // console.log({ findPublicPath });

  useEffect(() => {
    const checkAuthentication = async () => {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken && !findPublicPath) {
        navigate("/login");
        return;
      }
      if (accessToken && findPublicPath) {
        navigate("/details");
      }
    };

    checkAuthentication();
  }, [navigate]);

  return <>{children}</>;
};

export default RouteGuard;
