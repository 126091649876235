import { Button, Form, Input, message } from "antd";
import { NavBar } from "./NavBar";
import { axiosInstance } from "../axios/axiosInstance";
const onFinish = async (values: any) => {
  // console.log(values);
  const prospects = [
    {
      linkedin: values.linkedin,
    },
  ];

  const senderInformation = {
    services: values.textarea,
    system_role: values.roleOfSystem,
    blurb: values.blurb,
  };

  const payload = {
    prospects,
    sender_information: senderInformation,
  };

  console.log(payload);

  if (values.linkedin && !validateLinkedInProfile(values.linkedin)) {
    message.error("Please enter a valid LinkedIn profile URL.");
    return;
  }

  try {
    await axiosInstance.post("/awsmessage", {
      messageBody: payload,
    });
    message.success("Message Sent Successfully!");
  } catch (error) {
    console.error("Error sending message:", error);
    message.error("Error in Sending Message!");
  }
};

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

type FieldType = {
  // email?: string;
  linkedin?: string;
  textarea?: string;
  roleOfSystem?: string;
  // roleOfUser?: string;
  blurb?: string;
};

const { TextArea } = Input;
const validateLinkedInProfile = (url: string) => {
  const regex = /^www\.linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/;
  return regex.test(url);
};
const Services = "{services}";
const UserDetails = () => {
  return (
    <>
      <NavBar />
      <div className="lg:p-12 lg:pb-0 lg:w-[60%] p-8 mb-10">
        <p className="text-2xl text-[#262626] font-bold mb-4 md:texgitt-3xl ">
          Welcome to Xstak Sales Pitch Bot
        </p>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {/* <label className="font-bold">Enter Email Address</label>
          <Form.Item<FieldType>
            name="email"
            rules={[
              { required: true, message: "Please enter your email!" },
              {
                type: "email",
                message: "Please enter a valid email address!",
              },
            ]}
          >
            <Input />
          </Form.Item> */}
          <label className="font-bold">
            Enter the LinkedIn Profile URL to Scrape
            <span style={{ marginLeft: "5px", color: "red" }}>*</span>
          </label>
          <Form.Item<FieldType>
            name="linkedin"
            rules={[
              {
                required: true,
                message: "Please input your LinkedIn Account!",
              },
              {
                validator: (_, value) => {
                  if (value && !validateLinkedInProfile(value)) {
                    return Promise.reject(
                      "Please enter a valid LinkedIn profile URL."
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="e.g:www.linkedin.com/in/username" />
          </Form.Item>

          <p className="font-bold mt-2">
            Services Example
            <span style={{ marginLeft: "5px", color: "red" }}>*</span>
          </p>
          <p>
            Shopdev specializes in automating business processes powered by
            custom ML and LLM models. Our team of over 100 professionals
            collaborates with businesses worldwide, providing product
            development and technology services. We have offices in San
            Francisco, Brussels, Dubai, and Lahore, making us your trusted
            digital innovation partner
          </p>
          <Form.Item<FieldType>
            name="textarea"
            rules={[{ required: true, message: "Please enter your Message!" }]}
          >
            <TextArea
              allowClear
              autoSize={{ minRows: 4, maxRows: 6 }}
              showCount
              maxLength={350}
            />
          </Form.Item>

          <p className="font-bold mt-2">
            Role Of System{" "}
            <span style={{ marginLeft: "5px", color: "red" }}>*</span>
          </p>
          <p>
            You are an outbound email marketer, our technology services company.
            shopdevs skill-set and the services it provides can be summarized
            as: {Services} . You are tasked with using the data scraped from a
            prospect’s and their company’s LinkedIn pages, matching this data to
            shopdev’s skill-set and the services shopdev offers and then
            generating a personalized email that resonates with the prospect and
            optimizes for response rates. You will maintain a friendly, business
            casual
          </p>

          <Form.Item<FieldType>
            name="roleOfSystem"
            rules={[
              { required: true, message: "Please enter role of system!" },
            ]}
          >
            <TextArea
              allowClear
              autoSize={{ minRows: 4, maxRows: 8 }}
              showCount
              maxLength={1000}
            />
          </Form.Item>
          {/* <p className="font-bold">Role Of User</p>
          <p>
            Please generate an email based on the following details on the
            prospect and their company, scraped from their LinkedIn profiles.
            <br />
            1)Prospects Name: __name__
            <br />
            2) Brief Introduction for the prospect: __intro__
            <br />
            3) The prospects recent professional experience is:
            __uniqueExperience__ <br />
            4) A brief overview of the prospects current company is:
            __companyOverviewData__ <br />
            5) A list of available job openings at the prospects company is:
            __jobInfoList__. <br />
            6) You can get the prospects company's
          </p>

          <Form.Item<FieldType>
            name="roleOfUser"
            rules={[{ required: true, message: "Please enter role of user!" }]}
          >
            <TextArea allowClear />
          </Form.Item> */}
          <p className="font-bold">Blurb</p>
          <p>
            This email was completely generated by ShopGPT, our fine tuned
            GPT-3.5 Turbo model. It took your LinkedIn profile URL and did its
            magic. The diagram below shows its architecture. Would love to tell
            you more about it!
          </p>

          <Form.Item name="blurb mt-2">
            <TextArea
              allowClear
              autoSize={{ minRows: 4, maxRows: 6 }}
              showCount
              maxLength={350}
            />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" className="bg-[blue] text-[white]">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default UserDetails;
