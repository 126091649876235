/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Col, Form, Input, Row, message } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { notification } from "antd";
import { axiosInstance } from "../axios/axiosInstance";
interface SignupForm {
  email: string;
  password: string;
  repeatPassword: string;
}

const Signup = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const onFinish = async (values: SignupForm) => {
    setError("");
    setSuccess(false);

    const { email, password, repeatPassword } = values;

    if (password !== repeatPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await axiosInstance.post("/signUp", {
        email: values.email,
        password: values.password,
        repeatPassword: values.repeatPassword,
      });
      if (response.data.status) {
        message.success({
          content:
            "Signup Successful! Please check your email for the confirmation code.",
        });
        setSuccess(true);
        setTimeout(() => {
          navigate("/confirm-signup");
        }, 2000);
      }
    } catch (error: any) {
      console.error("Error sending message:", error.response.data.error);
      setError(error.response.data.error);

      notification.error({
        message: "Signup Failed",
        description: error.response.data.error,
        placement: "bottomRight",
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="flex items-center justify-center flex-col h-screen">
      <img
        src={
          "https://uploads-ssl.webflow.com/6225e3bcdcd38d2a25b1df9e/62f4f1d8b5f15173d11b5d40_logo-xstak.svg"
        }
        alt="xStak"
        className="py-3"
      />
      <p className="text-2xl text-[#262626] font-bold mb-0 text-center md:text-3xl">
        Hi, Welcome to XStak Sales bot!
      </p>
      <h1 className="text-lg m-0 text-gray-8 text-center py-3">Sign Up</h1>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="flex flex-col w-full max-w-xs gap-3 space-y-0"
        labelCol={{ span: 24, className: "!pb-0" }}
        size="large"
        requiredMark={true}
      >
        <Form.Item
          label="Email"
          className="mb-0"
          name="email"
          rules={[
            { required: true, message: "Enter your email!" },
            {
              type: "email",
              message: "Please enter a valid email address!",
            },
          ]}
        >
          <Input placeholder="Enter your email!" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: "Enter your password!" },
            {
              max: 15,
              message: "Password length should be a minimum of 15 characters!",
            },
          ]}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>
        <Form.Item
          label="Repeat Password"
          name="repeatPassword"
          rules={[
            { required: true, message: "Repeat your password!" },
            {
              max: 15,
              message: "Password length should be a minimum of 15 characters!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Passwords do not match!");
              },
            }),
          ]}
        >
          <Input.Password placeholder="Repeat your password" />
        </Form.Item>
        <Row>
          <Col className="w-full">
            <Form.Item className="w-full">
              <Button
                size="large"
                className="block w-full mt-2 bg-[#007bff] text-white"
                htmlType="submit"
                block
              >
                Sign Up
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {/* {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-3 w-full max-w-xs">
          {error}
        </div>
      )} */}

      <p>
        Already have an Account?{" "}
        <strong className="ml-2 text-xl text-[#007bff]">
          <Link to="/">Sign in</Link>
        </strong>
      </p>
    </div>
  );
};

export default Signup;
