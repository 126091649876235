/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Upload } from "antd";
import { NavBar } from "./NavBar";
import { axiosInstance } from "../axios/axiosInstance";
const { TextArea } = Input;

const ClusterDetails = () => {
  const [jsonData, setJsonData] = useState<null | any[]>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [custommessage, setCustomMessage] = useState("");
  const [systemRole, setSystemRole] = useState("");
  const [blurb, setBlurb] = useState("");

  const convertCSVToJson = (csvData: string | ArrayBuffer | null) => {
    if (typeof csvData === "string") {
      const lines = csvData.split("\n");
      const headers = lines[0].split(",");
      const result = [];

      for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(",");

        if (currentLine.length === headers.length) {
          const obj: Record<string, string> = {};

          for (let j = 0; j < headers.length; j++) {
            obj[headers[j].trim()] = currentLine[j].trim();
          }

          result.push(obj);
        }
      }

      return result;
    }

    return null;
  };

  const handleCSVUpload = (file: File) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      if (e.target && typeof e.target.result === "string") {
        const csvData = e.target.result;
        const jsonData = convertCSVToJson(csvData);
        setJsonData(jsonData);
        setUploadedFile(file);
        setIsFileUploaded(true);
        message.success(`${file.name} file uploaded successfully.`);
      }
    };

    reader.readAsText(file);
  };

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    showUploadList: true,
    beforeUpload: (file) => {
      handleCSVUpload(file);
      return false;
    },
  };

  const onFinish = async ({
    jsonData,
    custommessage,
    systemRole,
    blurb,
  }: any) => {
    if (!jsonData || !jsonData.length) {
      message.error("No data to send.");
      return;
    }
    const sender_information = {
      services: custommessage,
      system_role: systemRole,
      blurb: blurb,
    };
    const resultPayload = {
      prospects: jsonData,
      sender_information: sender_information,
    };
    console.log("resultPayload: ", resultPayload);

    try {
      await axiosInstance.post("/awsmessage", {
        messageBody: resultPayload,
      });
      setTimeout(() => {
        message.success("Message Sent Successfully!");
      }, 3000);
    } catch (error) {
      console.error("Error sending message:", error);
      message.error("Error in Sending Message!");
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const Services = "{services}";
  return (
    <>
      <NavBar />
      <div className="lg:p-8 lg:pb-0 lg:w-[60%] p-8 mb-10">
        <p className="text-2xl text-[#262626] font-bold mb-0 md:text-3xl pb-8">
          Bulk Email Generation
        </p>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={() =>
            onFinish({ jsonData, custommessage, systemRole, blurb })
          }
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Upload.Dragger {...uploadProps} accept=".csv">
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag CSV file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single CSV file. Strictly prohibited from uploading
              company data or other banned files.
            </p>
          </Upload.Dragger>

          <p className="mt-6 text-[#262626] font-bold">
            Services Example
            <span style={{ marginLeft: "5px", color: "red" }}>*</span>
          </p>
          <p>
            Shopdev specializes in automating business processes powered by
            custom ML and LLM models. Our team of over 100 professionals
            collaborates with businesses worldwide, providing product
            development and technology services. We have offices in San
            Francisco, Brussels, Dubai, and Lahore, making us your trusted
            digital innovation partner
          </p>

          <Form.Item
            name="textarea"
            rules={[{ required: true, message: "Please enter your Message!" }]}
          >
            <TextArea
              allowClear
              value={custommessage}
              onChange={(e) => setCustomMessage(e.target.value)}
              showCount
              maxLength={350}
              autoSize={{ minRows: 4, maxRows: 6 }}
            />
          </Form.Item>

          <p className="font-bold mt-2">
            Role Of System
            <span style={{ marginLeft: "5px", color: "red" }}>*</span>
          </p>
          <p>
            You are an outbound email marketer, our technology services company.
            shopdevs skill-set and the services it provides can be summarized
            as: {Services}. You are tasked with using the data scraped from a
            prospect’s and their company’s LinkedIn pages, matching this data to
            shopdev’s skill-set and the services shopdev offers and then
            generating a personalized email that resonates with the prospect and
            optimizes for response rates. You will maintain a friendly, business
            casual
          </p>

          <Form.Item
            name="roleOfSystem"
            rules={[
              { required: true, message: "Please enter role of system!" },
            ]}
          >
            <TextArea
              value={systemRole}
              onChange={(e) => setSystemRole(e.target.value)}
              showCount
              maxLength={1000}
              allowClear
              autoSize={{ minRows: 4, maxRows: 8 }}
            />
          </Form.Item>
          {/* <p className="font-bold">Role Of User</p>
          <p>
            Please generate an email based on the following details on the
            prospect and their company, scraped from their LinkedIn profiles.
            <br />
            1)Prospects Name: __name__
            <br />
            2) Brief Introduction for the prospect: __intro__
            <br />
            3) The prospects recent professional experience is:
            __uniqueExperience__ <br />
            4) A brief overview of the prospects current company is:
            __companyOverviewData__ <br />
            5) A list of available job openings at the prospects company is:
            __jobInfoList__. <br />
            6) You can get the prospects company's
          </p>

          <Form.Item
            name="roleOfUser"
            rules={[{ required: true, message: "Please enter role of user!" }]}
          >
            <TextArea
              value={userRole}
              onChange={(e) => setUserRole(e.target.value)}
              allowClear
            />
          </Form.Item> */}
          <p className="font-bold mt-2">Blurb</p>
          <p>
            This email was completely generated by ShopGPT, our fine tuned
            GPT-3.5 Turbo model. It took your LinkedIn profile URL and did its
            magic. The diagram below shows its architecture. Would love to tell
            you more about it!
          </p>

          <Form.Item name="blurb">
            <TextArea
              value={blurb}
              onChange={(e) => setBlurb(e.target.value)}
              showCount
              maxLength={350}
              allowClear
              autoSize={{ minRows: 4, maxRows: 6 }}
            />
          </Form.Item>

          <Form.Item>
            <Button className="bg-[blue] text-[white]" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ClusterDetails;
