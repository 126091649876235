/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Form, Input, message, notification } from "antd";
import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../axios/axiosInstance";
interface ForgotPasswordForm {
  email: string;
}
interface ResetPasswordForm {
  newPassword: string;
  confirmationCode: string;
}
export default function ResetPassword() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [confirmationSent, setConfirmationSent] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const handleConfirmSubmit = async (values: ForgotPasswordForm) => {
    setError("");

    try {
      const response = await axiosInstance.post("/forgotPassword", {
        email: values.email,
      });

      setConfirmationSent(response.data?.status);
      setEmail(values.email);
      if (response.data.status) {
        message.success({
          content: "Confirmation code send To the email",
        });
      }
    } catch (error: any) {
      console.error("Error sending message:", error.response.data.error);
      setError(error.response.data.error);

      notification.error({
        message: "Error",
        description: error.response.data.error,
        placement: "bottomRight",
      });
    }
  };
  const handleSubmit = async (values: ResetPasswordForm) => {
    setError("");

    try {
      const response = await axiosInstance.post("/confirmForgot", {
        email: email,
        newPassword: values?.newPassword,
        confirmationCode: values?.confirmationCode,
      });

      setConfirmationSent(response.data?.status);
      if (response.data.status) {
        message.success({
          content: "Password Changed",
        });
        setSuccess(true);
      }
    } catch (error: any) {
      console.error(
        "Error on Confirming Forgot Password:",
        error.response.data.error
      );
      setError(error.response.data.error);

      notification.error({
        message: "Error",
        description: error.response.data.error,
        placement: "bottomRight",
      });
    }
  };

  if (success) {
    return (
      <div className="flex items-center justify-center flex-col h-screen">
        <h2>Reset password</h2>
        <p>Your password has been reset successfully!</p>
        <strong className="ml-2 text-xl text-[#007bff]">
          <Link to="/">Login</Link>
        </strong>
      </div>
    );
  }
  if (success) {
    return (
      <div className="flex items-center justify-center flex-col h-screen">
        <h2>Reset password</h2>
        <p>Your password has been reset successfully!</p>
        <strong className="ml-2 text-xl text-[#007bff]">
          <Link to="/">Login</Link>
        </strong>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center flex-col h-screen">
      <img
        src={
          "https://uploads-ssl.webflow.com/6225e3bcdcd38d2a25b1df9e/62f4f1d8b5f15173d11b5d40_logo-xstak.svg"
        }
        alt="xStak"
        className="py-3"
      />
      <h1 className="text-lg m-0 text-gray-8 text-center py-3">
        Reset your password
      </h1>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleConfirmSubmit}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="flex flex-col w-full max-w-xs gap-3 space-y-0"
        labelCol={{ span: 24, className: "!pb-0" }}
        size="large"
        requiredMark={true}
      >
        <Form.Item
          label="Email"
          className="mb-0"
          name="email"
          rules={[
            { required: true, message: "Enter your email!" },
            {
              type: "email",
              message: "Please enter a valid email address!",
            },
          ]}
        >
          <Input placeholder="Enter your email!" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="block w-full mt-4 bg-[#007bff] text-white"
            block
          >
            {confirmationSent
              ? "Resend confirmation code"
              : "Send confirmation code"}
          </Button>
        </Form.Item>
      </Form>
      {confirmationSent && (
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="flex flex-col w-full max-w-xs gap-3 space-y-0 mt-5"
          labelCol={{ span: 24, className: "!pb-0" }}
          size="large"
          requiredMark={true}
        >
          <Form.Item
            className="mb-0"
            label="New Password"
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
            ]}
          >
            <Input.Password placeholder="New password" />
          </Form.Item>
          <Form.Item
            label="Confirmation Code"
            name="confirmationCode"
            rules={[
              { required: true, message: "Enter your confirmation code!" },
            ]}
          >
            <Input placeholder="Enter your confirmation code!" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="block w-full mt-4 bg-[#007bff] text-white"
              block
            >
              Confirm
            </Button>
          </Form.Item>
        </Form>
      )}

      <div className="py-4 w-72 first-letter:">
        <hr />
      </div>
      <strong className="ml-2 text-xl text-[#007bff]">
        <Link to="/">Login</Link>
      </strong>
    </div>
  );
}
