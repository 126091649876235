import { message } from "antd";
import axios from "axios";
import jwt_decode from "jwt-decode";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
let controller = new AbortController();
axiosInstance.interceptors.request.use(
  (config) => {
    console.log("request", { config });

    const accessToken = localStorage.getItem("accessToken");
    const useridToken = localStorage.getItem("idToken");
    config.signal = controller.signal;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers.idToken = `${useridToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    console.log("response", { err });

    const originalRequest = err.config;
    if (err?.response?.status === 401 && !originalRequest._retry) {
      console.log("response 401", { err });

      controller.abort();
      controller = new AbortController();
      originalRequest._retry = true;
      const data = {
        accessToken: localStorage.getItem("refreshToken"),
      };
      const requestConfig = {
        url: "https://sale-pitch-backend-dev.xstak.com/validateToken",
        method: "POST",
        data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
      };
      try {
        const accessToken = localStorage.getItem("accessToken");
        const decoded_access_token: any = jwt_decode(`${accessToken}`);
        const expirationTime = Date.now() + 4 * 24 * 60 * 60 * 1000;
        decoded_access_token.exp = Math.floor(expirationTime / 1000);
        if (decoded_access_token.exp < Date.now() / 1000) {
          localStorage.clear();
          window.location.href = window.location.origin;
          message.success({
            content:
              "Your session expired, login again to pickup where you left off.",
          });
          return;
        }
        const res = await axios(requestConfig);
        const newAccessToken = res.data.accessToken;
        // console.log({ newAccessToken });

        localStorage.setItem("accessToken", newAccessToken);
      } catch (_error) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("idToken");

        window.location.href = window.location.origin;
        return Promise.reject(_error);
      }
    }
    return Promise.reject(err);
  }
);
