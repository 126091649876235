/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, message, notification } from "antd";

import axios from "axios";
import { axiosInstance } from "../axios/axiosInstance";

interface ConfirmSignUpForm {
  email: string;
  confirmationCode: string;
}

const ConfirmSignUp = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values: ConfirmSignUpForm) => {
    setError("");
    setSuccess(false);

    const { email, confirmationCode } = values;
    try {
      const response = await axiosInstance.post("/confirmSignup", {
        email: values.email,
        confirmationCode: values.confirmationCode,
      });
      if (response.data.status) {
        message.success({
          content: "Email Verified.",
        });
        setSuccess(true);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (error: any) {
      console.error("Error sending message:", error.response.data.error);
      setError(error.response.data.error);

      notification.error({
        message: "Email Verification Fail",
        description: error.response.data.error,
        placement: "bottomRight",
      });
    }
  };

  return (
    <div className="flex items-center justify-center flex-col h-screen">
      <img
        src={
          "https://uploads-ssl.webflow.com/6225e3bcdcd38d2a25b1df9e/62f4f1d8b5f15173d11b5d40_logo-xstak.svg"
        }
        alt="xStak"
        className="py-3"
      />
      <h1 className="text-lg m-0 text-gray-8 text-center py-3">
        Confirm Sign Up
      </h1>
      <p className="text-sm text-gray-8 text-center py-3">
        Please check your email for the confirmation code.
      </p>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="w-full max-w-xs space-y-0"
        labelCol={{ span: 24, className: "!pb-0" }}
        size="large"
      >
        <Form.Item
          className="mb-0"
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Enter your email!" },
            {
              type: "email",
              message: "Please enter a valid email address!",
            },
          ]}
        >
          <Input placeholder="Enter your email!" />
        </Form.Item>
        <Form.Item
          label="Confirmation Code"
          name="confirmationCode"
          rules={[{ required: true, message: "Enter your confirmation code!" }]}
        >
          <Input placeholder="Enter your confirmation code!" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="block w-full mt-4 bg-[#007bff] text-white"
            block
          >
            Confirm
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ConfirmSignUp;
