/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Form, Input, message, notification } from "antd";
import { useState, useContext } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { axiosInstance } from "../axios/axiosInstance";

interface LoginForm {
  email: string;
  password: string;
}

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

const Login = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const onFinish = async (values: LoginForm) => {
    setError("");

    const { email, password } = values;
    try {
      const response = await axiosInstance.post("/signIn", {
        email: values.email,
        password: values.password,
      });
      localStorage.setItem("accessToken", response.data.accessToken);
      localStorage.setItem("idToken", response.data.idToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      if (response.data) {
        message.success({
          content: "User Logged In.",
        });
        navigate("/details");
      }
    } catch (error: any) {
      console.error("Error sending message:", error.response.data.error);
      setError(error.response.data.error);

      notification.error({
        message: "Login Failed",
        description: error.response.data.error,
        placement: "bottomRight",
      });
    }
  };

  return (
    <div className="flex items-center justify-center flex-col h-screen">
      <img
        src={
          "https://uploads-ssl.webflow.com/6225e3bcdcd38d2a25b1df9e/62f4f1d8b5f15173d11b5d40_logo-xstak.svg"
        }
        alt="xStak"
        className="py-3"
      />
      <p className="text-2xl text-[#262626] font-bold mb-0 text-center md:texgitt-3xl ">
        Hi, Welcome to XStak Sales bot!
      </p>
      <p className="text-base m-0 text-gray-8 text-center py-3 pb-6">
        Empowering Your Sales Pitch
      </p>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="w-full max-w-xs"
        labelCol={{ span: 24, className: "!pb-0" }}
        size="large"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Enter your email!" },
            {
              type: "email",
              message: "Please enter a valid email address!",
            },
          ]}
        >
          <Input placeholder="Enter your email!" type="email" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password placeholder="Please input your password" />
        </Form.Item>

        <Form.Item>
          <Button
            block
            className="h-[40px] mr-2 bg-[#007bff] text-white"
            htmlType="submit"
          >
            Login
          </Button>
        </Form.Item>
      </Form>

      <p>
        Create an Account?
        <strong className="ml-2 text-l text-[#007bff]">
          <Link to="/signup">Sign up</Link>
        </strong>
      </p>
      <p>
        Forgot Password?
        <strong className="ml-2 text-l text-[#007bff]">
          <Link to="/forgot-password">Click Here</Link>
        </strong>
      </p>
    </div>
  );
};

export default Login;
