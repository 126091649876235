/* eslint-disable @typescript-eslint/no-unused-vars */
import { Menu, MenuProps, Button } from "antd";
import { useState } from "react";
import { MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const items: MenuProps["items"] = [
  {
    label: <Link to="/details">Individual Mail</Link>,
    key: "mail",
    icon: <MailOutlined />,
  },
  {
    label: <Link to="/megadetails">Bulk Mailing</Link>,
    key: "bulk",
    icon: <MailOutlined />,
  },
  {
    label: (
      <Button
        type="primary"
        danger
        onClick={() => {
          localStorage.clear();
          window.location.href = window.location.origin;
        }}
      >
        Sign Out
      </Button>
    ),
    key: "signout",
  },
];
export const NavBar = () => {
  const [current, setCurrent] = useState<string>("");

  const handleClick = (e: any) => {
    setCurrent(e.key);
  };

  return (
    <Menu
      onClick={handleClick}
      selectedKeys={[current]}
      mode="horizontal"
      items={items}
    />
  );
};
