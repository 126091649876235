import { Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import UserDetails from "./components/UserDetails";
import ClusterDetail from "./components/ClusterDetail";
import Signup from "./components/Signup";
import ConfirmSignUp from "./components/ConfirmSignUp";
// import RouteGuard from "./components/RouteGuard";
import ResetPassword from "./components/ResetPassword";
import RouteGuard from "./RouteGuard";
import Home from "./components/home/Home";

const RoutesProvider = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/login"
        element={
          <RouteGuard>
            <Login />
          </RouteGuard>
        }
      />
      <Route
        path="/signup"
        element={
          <RouteGuard>
            <Signup />
          </RouteGuard>
        }
      />
      <Route
        path="/confirm-signup"
        element={
          <RouteGuard>
            <ConfirmSignUp />
          </RouteGuard>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <RouteGuard>
            <ResetPassword />
          </RouteGuard>
        }
      />
      <Route
        path="/details"
        element={
          <RouteGuard>
            <UserDetails />
          </RouteGuard>
        }
      />
      <Route
        path="/megadetails"
        element={
          <RouteGuard>
            <ClusterDetail />
          </RouteGuard>
        }
      />
    </Routes>
  );
};

export default RoutesProvider;
